// @flow
import React from 'react';
import type { FrontMatter, ImageType } from '../../utils/types';
import route from '../../routes';
import {
  AllImagesLink,
  CanyonRatingCard,
  Image,
  Layout,
  Link,
  withFrontMatter,
} from '../../components';
import { graphql } from 'gatsby';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _01: ImageType,
    _02: ImageType,
    _03: ImageType,
    _04: ImageType,
    _05: ImageType,
    _06: ImageType,
    _07: ImageType,
    _08: ImageType,
    _09: ImageType,
  },
};

class Blog_2020_09_27_Great_White_Icicle extends React.PureComponent<Props> {
  render() {
    const { frontMatter, data } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3CII" title="Great White Icicle" />
        <p>
          Last descent of the year came a little earlier than usual because of
          the surging COVID-19 numbers. Still a fun little trip with good
          friends.
        </p>
        <Image
          image={data._01}
          caption="Looking up at the Great White Icicle from the bottom. The visible section of the waterfall is 400 ft tall"
        />
        <p>
          There were three of us this morning and all of us were very competent
          individuals so the trip was uneventful in terms of challenges but very
          fun to catch up with these friends.
        </p>
        <Image
          image={data._02}
          caption="The crew at the top of the first rappel"
        />
        <p>
          The first three rappels are a sequence where a limited number of
          people can be at each station.
        </p>
        <Image image={data._03} caption="Me on the first rappel" />
        <Image image={data._04} caption="Second rappel" />
        <p>
          The second and third rappels are quite long and the station between
          them kind of reminds me of the bird perch in{' '}
          <Link to={route.blog_2017_06_10_heaps}>Heaps</Link>.
        </p>
        <Image image={data._05} caption="Station between rappels 2 and 3" />
        <p>
          The third rappel is the longest at just over 200 ft though a 200' rope
          works with some scrambling at the end.
        </p>
        <Image
          image={data._06}
          caption="Rappel 3 - final rappel in the sequence"
        />
        <Image image={data._07} caption="Looking up at rappels 2 and 3" />
        <p>
          After the rappels we bagged ropes, warmed up and continued the short
          distance to the next rappel. There are few places we could rappel from
          here and this time we chose the higher one.
        </p>
        <Image image={data._08} caption="Rappel 4" />
        <p>
          The final rappel can be down climbed through the water course. One of
          us chose to do that and the other two did one final rappel off some
          bolts down a slab.
        </p>
        <Image
          image={data._09}
          caption="Bottom of the cascade enjoying the hike down and out"
        />
        <p>
          Once at the bottom, we geared down and hiked back to the bridge where
          our cars were waiting.
        </p>
        <AllImagesLink id="2020-09-27-great-white-icicle" />
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    _01: file(
      relativePath: { eq: "images/blog/2020-09-27-great-white-icicle/_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _02: file(
      relativePath: { eq: "images/blog/2020-09-27-great-white-icicle/_02.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _03: file(
      relativePath: { eq: "images/blog/2020-09-27-great-white-icicle/_03.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _04: file(
      relativePath: { eq: "images/opengraph/2020-09-27-great-white-icicle.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _05: file(
      relativePath: { eq: "images/blog/2020-09-27-great-white-icicle/_05.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _06: file(
      relativePath: { eq: "images/blog/2020-09-27-great-white-icicle/_06.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _07: file(
      relativePath: { eq: "images/blog/2020-09-27-great-white-icicle/_07.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _08: file(
      relativePath: { eq: "images/blog/2020-09-27-great-white-icicle/_08.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _09: file(
      relativePath: { eq: "images/blog/2020-09-27-great-white-icicle/_09.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default withFrontMatter('2020-09-27-great-white-icicle')(
  Blog_2020_09_27_Great_White_Icicle
);
